import { PENDING, sortByTime } from '@pumpkincare/shared';

import { PEP_PLAN_STATUS } from './plan-config-utils';

export function transformPayment(payment) {
  return {
    fundingSource: {
      accountNumber: payment.funding_source?.account_number ?? '',
      accountType: payment.funding_source?.account_type ?? '',
      failureReason: payment.funding_source?.failure_reason,
      nameOnAccount: payment.funding_source?.name_on_account ?? '',
      received: payment.funding_source?.received,
      responseReceived: payment.funding_source?.response_received,
      routingNumber: payment.funding_source?.routing_number ?? '',
      verified: payment.funding_source?.verified,
    },

    paymentMethod: {
      brand: payment.payment_method.brand,
      expMonth: payment.payment_method.exp_month,
      expYear: payment.payment_method.exp_year,
      funding: payment.payment_method.funding,
      last4: payment.payment_method.last4,
      name: payment.payment_method.name,
    },
  };
}

export function reverseSortPlans(plans) {
  if (!plans.length) return [];

  const sortedPlans = plans.sort(
    (a, b) => new Date(b.plan_effective_date) - new Date(a.plan_effective_date)
  );

  // make sure that plans with the same effective date are sorted consistently bc they're ordered by ids via BE response
  if (
    sortedPlans[0].status === PEP_PLAN_STATUS.VOIDED &&
    sortedPlans[1]?.status === PEP_PLAN_STATUS.RENEW_PENDING
  ) {
    const temp = sortedPlans[1];
    sortedPlans[1] = sortedPlans[0];
    sortedPlans[0] = temp;
  }

  return sortedPlans;
}

const GENDER_GRAMMAR = {
  F: {
    subject: 'she',
    object: 'her',
    possessive: 'her',
  },
  M: {
    subject: 'he',
    object: 'him',
    possessive: 'his',
  },
  default: {
    subject: 'they',
    object: 'their',
    possessive: 'their',
  },
};

export function transformUser(user, invoices = []) {
  return {
    email: user.email,
    externalId: `PKN${user.external_id}`,
    firstName: user.first_name,
    id: user.id,
    invoices,
    isChargedAnnually: user.is_charged_annually,
    lapsedSince: user.lapsed_since,
    lastName: user.last_name,
    monthlyChargeDay: user.monthly_charge_day,
    nextChargeDate: user.next_charge_date,
    pets: user.pets.map(pet => ({
      ...pet,
      genderGrammar: GENDER_GRAMMAR[pet?.gender || 'default'],
      plans: reverseSortPlans(pet.plans ?? []),
      policies: sortByTime(pet.policies ?? []),
      wellness: sortByTime(pet.wellness ?? []),
    })),
    phone: user.phone,

    transactionFee: Math.floor(user.transaction_fee / 100), // returned in cents vs in dollars for quote
  };
}

export function transformUserVets(vets) {
  return vets.map(vet => ({
    ...vet,
    permissions: {
      ...vet.permissions,
      needsClaimAction: vet.permissions.can_file_claim_status === PENDING,
      needsDataAction: vet.permissions.can_view_data_status === PENDING,
    },
  }));
}

export function sortMonthlyStatement(invoices, orders = 'asc') {
  return invoices.sort(function (a, b) {
    return orders === 'desc'
      ? new Date(b.created_at) - new Date(a.created_at)
      : new Date(a.created_at) - new Date(b.created_at);
  });
}
