export function responseDataBody(response) {
  return response?.data?.body || response?.data;
}

export function buildAuthHeaders(user) {
  return {
    headers: {
      Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
    },

    response: true,
  };
}
