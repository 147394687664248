import { useQuery } from 'react-query';

import { getIsLoggedIn } from '@pumpkincare/shared';

import { getUserPaymentMethod } from './user-service';

export const PAYMENT_QUERY = 'payment';
export const PAYMENT_QUERY_PLACEHOLDER = {
  fundingSource: {
    accountNumber: '',
    accountType: '',
    failureReason: '',
    nameOnAccount: '',
    received: '',
    responseReceived: '',
    routingNumber: '',
    verified: '',
  },

  paymentMethod: {
    brand: '',
    expMonth: '',
    expYear: '',
    funding: '',
    last4: '',
    name: '',
  },
};

export const getReimbursementTargetAccountNumber = payment =>
  payment.fundingSource.accountNumber;
export const getReimbursementTargetAccountType = payment =>
  payment.fundingSource.accountType;
export const getReimbursementTargetFailureReason = payment =>
  payment.fundingSource.failureReason;
export const getReimbursementTargetNameOnAccount = payment =>
  payment.fundingSource.nameOnAccount;
export const getReimbursementTargetReceived = payment =>
  payment.fundingSource.received;
export const getReimbursementTargetResponseReceived = payment =>
  payment.fundingSource.responseReceived;
export const getReimbursementTargetRoutingNumber = payment =>
  payment.fundingSource.routingNumber;
export const getReimbursementTargetVerified = payment =>
  payment.fundingSource.verified;

export const getPaymentMethodBrand = payment => payment.paymentMethod.brand;
export const getPaymentMethodExpMonth = payment => payment.paymentMethod.expMonth;
export const getPaymentMethodExpYear = payment => payment.paymentMethod.expYear;
export const getPaymentMethodFunding = payment => payment.paymentMethod.funding;
export const getPaymentMethodLast4 = payment => payment.paymentMethod.last4;
export const getPaymentMethodName = payment => payment.paymentMethod.name;

export function usePayment(select) {
  return useQuery(
    [PAYMENT_QUERY],

    () => getUserPaymentMethod(),

    {
      enabled: getIsLoggedIn(),

      placeholderData: PAYMENT_QUERY_PLACEHOLDER,

      select,
      staleTime: Infinity,
    }
  );
}
