import { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import {
  Body1,
  Body2,
  ButtonStyles,
  LegalBody,
  TextField,
  Typography,
} from '@pumpkincare/shared/ui';

import { useVetClinics } from '../../vet-clinics-query';

import styles from './vet-clinic-search.css';

export const DEFAULT_NO_VET_RESULTS = {
  primary: "I don't see my vet clinic on this list",
  secondary: 'No matching vet clinics found',
};

function VetClinicSearch(props) {
  const {
    onChange,
    onSelect,
    onClear,
    disabled,
    onEnterInfo,
    canShowForm,
    zipcode,
    classes,
    isInline,
    noneOption,
    stickyFooter,
    ...restInputProps
  } = props;

  const searchInputRef = useRef();
  const searchInputHasFocusRef = useRef(false);
  // Controls if we should display the result list or the empty state
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { data, isFetching } = useVetClinics({
    searchTerm,
    zipcode,
    enabled: !disabled,
  });

  function setInputValue(value) {
    searchInputRef.current.value = value;
  }

  const debouncedTermChange = useDebouncedCallback(({ target: { value } }) => {
    if (value) {
      setSearchTerm(value);
    }

    onChange(value);

    if (value) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, 500);

  function handleClearIconClick() {
    setIsOpen(false);
    setInputValue('');

    onClear();
  }

  function handleListItemClick(item) {
    setIsOpen(false);
    setInputValue(item.vet_name);

    onSelect(item);
  }

  function handleEnterInfo() {
    setIsOpen(false);
    onEnterInfo({ value: searchInputRef.current.value });
  }

  function handleFocus() {
    if (searchTerm) setIsOpen(true);

    searchInputHasFocusRef.current = true;
  }

  function handleBlur() {
    if (props.defaultValue && searchTerm !== props.defaultValue)
      setInputValue(props.defaultValue);
    searchInputHasFocusRef.current = false;

    setIsOpen(false);
  }

  function highlight(vetName) {
    if (!searchTerm) {
      return vetName;
    }

    const startIndex = vetName.search(new RegExp(searchTerm, 'i'));
    if (startIndex === -1) {
      return vetName;
    }

    const endIndex = startIndex + searchTerm.length;

    return (
      <>
        {startIndex > 0 ? vetName.substring(0, startIndex) : null}
        <b>{vetName.substring(startIndex, endIndex)}</b>
        {endIndex < vetName.length ? vetName.substring(endIndex) : null}
      </>
    );
  }

  // the dropdown should only use onMouseDown to address blur event cycle
  function showResults() {
    if (!isOpen || (isFetching && !data.length)) {
      return null;
    }

    const paperClassName = classNames(styles.paper, classes.paper, {
      [styles.inline]: isInline,
    });

    if (data.length) {
      return (
        <div className={paperClassName}>
          <ul
            className={classNames(styles.resultsList, {
              [styles.footer]: !!stickyFooter,
            })}
          >
            {data.map((item, index) => {
              return (
                <div
                  role='button'
                  className={classNames(styles.resultsItem, {
                    [styles.active]: item.vet_name === props.defaultValue,
                  })}
                  key={index}
                  onMouseDown={() => handleListItemClick(item)}
                  data-testid='option'
                >
                  <LegalBody className={styles.resultsPrimary}>
                    {highlight(item.vet_name)}
                  </LegalBody>
                  <LegalBody className={styles.resultsSecondary}>
                    {item.normalized_addr}
                  </LegalBody>
                </div>
              );
            })}
          </ul>

          {stickyFooter ? (
            <button
              className={classNames(styles.stickyFooter, Typography.legalBody)}
              onMouseDown={() => handleListItemClick({ vet_name: stickyFooter })}
            >
              <b>{stickyFooter}</b>
            </button>
          ) : null}
        </div>
      );
    }

    if (!canShowForm) {
      return (
        <div className={paperClassName}>
          <ul className={styles.noResultsList}>
            <div
              role='button'
              className={styles.noResultsListItem}
              onMouseDown={() =>
                handleListItemClick({ vet_name: noneOption.primary })
              }
            >
              <LegalBody className={styles.noResultsPrimary}>
                {noneOption.primary}
              </LegalBody>

              <LegalBody className={styles.noResultsSecondary}>
                {noneOption.secondary}
              </LegalBody>
            </div>
          </ul>
        </div>
      );
    }

    return (
      <div className={paperClassName}>
        <>
          <ul className={styles.noResultsList}>
            <div key={'no-result'} className={styles.noResultsListItem}>
              <Body1 className={styles.noResultsPrimary}>
                {'No Matching Vet Found'}
              </Body1>

              <LegalBody className={styles.noResultsSecondary}>
                {'No worries! You can enter your vet info manually'}
              </LegalBody>
            </div>
          </ul>

          <Body2
            className={styles.ctaTitle}
          >{`Don't see your vet? No problem!`}</Body2>

          <button
            className={classNames(ButtonStyles.baseButton, styles.cta)}
            onMouseDown={handleEnterInfo}
          >
            Enter My Vet's Info
          </button>
        </>
      </div>
    );
  }

  const isSearch = !searchInputRef.current || !searchInputRef.current.value;

  return (
    <div className={classNames(styles.root, classes.root)}>
      <div className={styles.width}>
        <TextField
          id='vet-clinic-search'
          inputRef={searchInputRef}
          onChange={debouncedTermChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          readOnly={disabled}
          classes={{ container: classNames(styles.textField, classes.textField) }}
          endAdornment={{
            icon: isSearch ? 'search' : 'close',
            onIconClick: isSearch ? () => {} : handleClearIconClick,
            isLoading: searchInputHasFocusRef.current && isFetching,
          }}
          data-testid='vet-clinic-search'
          {...restInputProps}
        />

        {showResults()}
      </div>
    </div>
  );
}

VetClinicSearch.defaultProps = {
  disabled: false,
  defaultValue: '',
  onChange: () => {},
  onSelect: () => {},
  onClear: () => {},
  onEnterInfo: () => {},
  canShowForm: true,
  placeholder: 'e.g. Ark Animal, Clinic',
  noneOption: DEFAULT_NO_VET_RESULTS,
  classes: {},
};

VetClinicSearch.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    paper: PropTypes.string,
    textField: PropTypes.string,
  }),

  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
  onEnterInfo: PropTypes.func,
  canShowForm: PropTypes.bool,
  zipcode: PropTypes.string,
  isInline: PropTypes.bool,
  noneOption: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
  stickyFooter: PropTypes.string,

  // rest spread via restInputProps
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
};

export default VetClinicSearch;
