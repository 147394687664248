import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  captureException,
  formatCurrency,
  FORMATTED_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body2, LegalBody } from '@pumpkincare/shared/ui';

import { downloadPlanDoc } from '../../user-service';
import CoverageDates from '../coverage-dates';
import CoverageDocuments from '../coverage-documents';

import styles from './pet-plan-overview.css';

function PetPlanOverview({
  classes,
  petName,
  hasPlans,
  entitlements,
  price,
  documents,
  dateConfig,
}) {
  function handleDocumentClick({ id }) {
    downloadPlanDoc(id).then(url => {
      if (url) {
        window.open(url);
      } else {
        captureException(`No url returned for pet_plan_id ${id}`);
      }
    });
  }

  return (
    <div className={classNames(styles.root, classes.root)}>
      <div className={styles.overview}>
        <div className={styles.header}>
          <h5>Preventive Essentials</h5>

          {price.cost ? (
            <LegalBody>
              <b>{formatCurrency(price.cost)}</b> / {price.frequency} (for 12
              installments)
            </LegalBody>
          ) : null}
        </div>

        {hasPlans ? (
          <>
            <div className={styles.entitlements}>
              <Body2 isBold style={{ marginBottom: '8px' }}>
                Wellness Care Covered
              </Body2>

              <div className={styles.entitlementList}>
                {entitlements.map(entitlement => (
                  <LegalBody key={entitlement.description}>
                    {entitlement.quantity} {entitlement.description}
                  </LegalBody>
                ))}
              </div>
            </div>

            {documents.length ? (
              <CoverageDocuments
                documents={documents}
                title='Full Benefit Details'
                handleDocumentClick={handleDocumentClick}
              />
            ) : null}
          </>
        ) : (
          <div className={styles.upsell}>
            <Body2 isBold style={{ marginBottom: '8px' }}>
              Want to get refunds for routine wellness care?
            </Body2>
            <LegalBody>
              Check out Preventive Essentials – which isn’t insurance for accidents &
              illnesses, but an optional wellness package you can add to your plan to
              get 100% refunded for vaccines, wellness visits, and more!
            </LegalBody>

            <div className={styles.callout}>
              <LegalBody isBold>It's not too late to sign {petName} up!</LegalBody>
              <LegalBody>
                Call our care team at{' '}
                <a href={PHONE_NUMBER_LINK}>{FORMATTED_PHONE_NUMBER}</a> to{'\u00A0'}
                add{'\u00A0'}Prevent Essentials to your plan.
              </LegalBody>

              <img
                src='/assets/images/illustrations/product/welcome-box/right-dog-confetti.png'
                alt=''
              />
            </div>
          </div>
        )}
      </div>

      <div className={styles.datesAside}>
        {dateConfig.dates.length ? (
          <CoverageDates
            dates={dateConfig.dates}
            subtitle={dateConfig.subtitle}
            canContact={dateConfig.canContact}
          />
        ) : null}
      </div>
    </div>
  );
}

PetPlanOverview.defaultProps = {
  classes: {},
  price: {},
  documents: [],
  entitlements: [],
  dateConfig: { dates: [], canContact: false },
};

PetPlanOverview.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),

  price: PropTypes.shape({
    cost: PropTypes.number,
    frequency: PropTypes.oneOf(['month', 'year']),
  }),

  dateConfig: PropTypes.shape({
    dates: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
        subtitle: PropTypes.string,
      })
    ).isRequired,
    canContact: PropTypes.bool,
    subtitle: PropTypes.string,
  }),

  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      subtitle: PropTypes.string,
      processingText: PropTypes.string,
    })
  ),

  petName: PropTypes.string.isRequired,
  hasPlans: PropTypes.bool.isRequired,
  entitlements: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      quantity: PropTypes.number,
    })
  ),
};

export default PetPlanOverview;
